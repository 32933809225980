import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const EditDetails = ({ open, onClose, rowData, addUpdatedStatus }) => {
    const [formData, setFormData] = useState({
        id: '',
        projectName: '',
        description: '',
        remarks: '',
        isUpWorkProject: false,
        hoursOfWeek: '',
        rate: ''
    });

    const [rateError, setRateError] = useState(false); // State to track rate validation error
    const [descriptionError, setDescriptionError] = useState(false); // State to track description error
    const [hoursError, setHoursError] = useState(false); // State to track hours error

    useEffect(() => {
        if (rowData) {
            setFormData(rowData);
            console.log(rowData);
        }
    }, [rowData]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if required fields are provided
        if (!formData.rate) setRateError(true);
        if (!formData.description) setDescriptionError(true);
        if (!formData.hoursOfWeek) setHoursError(true);

        // Stop submission if any required field is missing
        if (!formData.rate || !formData.description || !formData.hoursOfWeek) {
            return;
        }

        addUpdatedStatus(formData.id, formData);
        onClose(); // Close modal after submission
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <h2 id="modal-title">Project Details</h2>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Project Name"
                        name="projectName"
                        value={formData.projectName}
                        onChange={handleChange}
                        margin="normal"
                        disabled={true}
                    />

                    <TextField
                        fullWidth
                        label="Remarks"
                        name="remarks"
                        value={formData.remarks}
                        onChange={handleChange}
                        margin="normal"
                    />

                    <TextField
                        fullWidth
                        label="Rate"
                        name="rate"
                        disabled={true}
                        value={formData.rate}
                        onChange={(e) => {
                            setRateError(false); // Clear error when value changes
                            handleChange(e);
                        }}
                        margin="normal"
                        type="number"
                        error={rateError} // Apply error state to the TextField
                        helperText={rateError ? "This field is required" : ""} // Display error message
                    />

                    <TextField
                        fullWidth
                        label="Hours"
                        name="hoursOfWeek"
                        value={formData.hoursOfWeek}
                        onChange={(e) => {
                            setHoursError(false); // Clear error when value changes
                            handleChange(e);
                        }}
                        margin="normal"
                        type="number"
                        error={hoursError} // Apply error state to the TextField
                        helperText={hoursError ? "This field is required" : ""} // Display error message
                    />

                    <TextField
                        fullWidth
                        label="Description"
                        name="description"
                        value={formData.description}
                        onChange={(e) => {
                            setDescriptionError(false); // Clear error when value changes
                            handleChange(e);
                        }}
                        margin="normal"
                        multiline
                        rows={4}
                        error={descriptionError} // Apply error state to the TextField
                        helperText={descriptionError ? "This field is required" : ""} // Display error message
                    />

                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
                        <Button
                            sx={{
                                backgroundColor: 'var(--navyblue1)', // Custom color
                            }} type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                        <Button
                            sx={{
                                color: 'var(--navyblue1)', // Custom color
                                borderColor: 'var(--navyblue1)',
                                '&:hover': {
                                    borderColor: 'var(--navyblue1)', // Custom color on hover
                                },
                            }}
                            type="button" variant="outlined" color="secondary" onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </form>
            </Box>
        </Modal>
    );
};

export default EditDetails;
